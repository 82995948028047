import React from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Img from "gatsby-image"
import styles from "./hero.module.scss"
import { useIsSsr } from "../../assets/js/helper"
import FormBuilder from "../forms/FormBuilder"
const Hero = ({ banner, customCTA, isLandingPage = false }) => {
  const isSsr = useIsSsr()
  const {
    mobile,
    background_image,
    secondary_image,
    heading,
    subheading,
    buttons,
    tagline,
  } = banner

  const bgOverlay = "PrimaryColor"

  const style = {
    background: `url(${background_image.value[0].fluid.src})`,
  }
  const mobileStyle = {
    background: `url(${mobile.value[0].fluid.src})`,
  }
  if (!isSsr && window.innerWidth < 768)
    return (
      <div
        style={mobileStyle}
        className={styles.hero}
        alt={mobile.value[0].description}
      >
        <div
          className={`${bgOverlay}Bg ${styles.opacity}`}
          id="hero-override"
        ></div>

        <section className="container flex-column-center ppcHero">
          <div
            className={
              isLandingPage ? styles.LandingContent : styles.bannerContent
            }
          >
            <h1>{heading.value}</h1>
            <RichTextElement value={subheading.value} className />
            <div className={styles.homePageBannerLinks}>
              {buttons.value.map((button, i) => {
                const { button_text, linked_page } = button.elements
                const { slug } = linked_page.value[0].elements
                let className = "primary-button"
                if (i === 1) className = "white-accent-ghost-button"
                return (
                  <Link key={button.id} to={slug.value} className={className}>
                    {button_text.value}
                  </Link>
                )
              })}
            </div>
            <div className={styles.callText}>
              {tagline.value ? (
                <p className={styles.smallText}>{tagline.value}</p>
              ) : (
                <p className={styles.smallText}>{customCTA}</p>
              )}
            </div>
          </div>
        </section>
        {secondary_image.value.length > 0 && (
          <Img
            fluid={secondary_image.value[0].fluid}
            className={styles.secondary}
            alt={secondary_image.value[0].description}
          />
        )}
      </div>
    )
  else
    return (
      <div
        style={style}
        className={styles.hero}
        alt={background_image.value[0].description}
      >
        <div
          className={`${bgOverlay}Bg ${styles.opacity}`}
          id="hero-override"
        ></div>
        <section className="container flex-column-center ppcHero">
          <div
            className={
              isLandingPage ? styles.landingContent : styles.bannerContent
            }
          >
            <h1>{heading.value}</h1>
            <RichTextElement value={subheading.value} className />
            {isLandingPage && secondary_image.value.length > 0 && (
              <Img
                fluid={secondary_image.value[0].fluid}
                className={styles.promo}
                alt={secondary_image.value[0].description}
              />
            )}
            <div className={styles.homePageBannerLinks}>
              {buttons.value.map((button, i) => {
                const { button_text, linked_page } = button.elements
                const { slug } = linked_page.value[0].elements
                let className = "primary-button"
                if (i === 1) className = "white-accent-ghost-button"
                return (
                  <Link key={button.id} to={slug.value} className={className}>
                    {button_text.value}
                  </Link>
                )
              })}
            </div>
            <div className={styles.callText}>
              {tagline.value ? (
                <p className={styles.smallText}>{tagline.value}</p>
              ) : (
                <p className={styles.smallText}>{customCTA}</p>
              )}
            </div>
          </div>
        </section>
        {isLandingPage && <div></div>}
        {!isLandingPage && secondary_image.value.length > 0 && (
          <Img
            fluid={secondary_image.value[0].fluid}
            className={styles.secondary}
            alt={secondary_image.value[0].description}
          />
        )}
      </div>
    )
}

export default Hero
