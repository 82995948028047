import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { AppContext } from "../context/context"
import { FaPhoneAlt } from "react-icons/fa"
import { BsEnvelope } from "react-icons/bs"
import SocialIcons from "../social-icons/SocialIcons"
import styles from "./secondary-header.module.scss"

const SecondaryHeader = ({
  phone,
  showReviews,
  showPortal,
  prLogo,
  reviewsLogo,
  social,
  navItems,
  location,
}) => {
  const { size, height } = React.useContext(AppContext)

  if (size < 768) {
    return (
      <section
        className={
          height > 1
            ? `${styles.secondaryHeader} ${styles.shadow}`
            : styles.secondaryHeader
        }
      >
        <div className={styles.secondaryHeaderInner}>
          <a href={`tel: ${phone}`} className={styles.phoneLink}>
            <FaPhoneAlt />
            {phone}
          </a>
          <Link to="/contact-us" className={styles.contactButton}>
            Contact Us <BsEnvelope />
          </Link>
        </div>
      </section>
    )
  } else {
    const prLogoPath = prLogo.nodes[0].childImageSharp.fixed
    const reviewLogoPath = reviewsLogo.nodes[0].childImageSharp.fixed
    return (
      <section className={styles.secondaryHeader}>
        <div className={`${styles.secondaryHeaderInner}`}>
          <div className={styles.socialWrapper}>
            {social.value.map(({ id, elements }) => {
              return <SocialIcons key={id} icons={elements} />
            })}
          </div>
          <div className={styles.headerRight}>
            {size >= 1024 && (
              <div className={styles.portalLinks}>
                {showPortal.codename === "no" ? (
                  ""
                ) : (
                  <a
                    href="/login"
                    className={`${styles.portalLink} ${
                      location === "/login" && styles.current
                    }`}
                  >
                    <Img
                      fixed={prLogoPath}
                      alt="Pestroutes Logo"
                      className={styles.prLogo}
                    />
                    Customer Login
                  </a>
                )}

                {showReviews.codename === "no" ? (
                  ""
                ) : (
                  <Link
                    to="/reviews"
                    className={styles.portalLink}
                    activeClassName={styles.current}
                  >
                    <Img
                      fixed={reviewLogoPath}
                      alt="Pestroutes Reviews Logo"
                      className={styles.reviewsIcon}
                    />
                    Customer Reviews
                  </Link>
                )}
                {navItems.value &&
                  navItems.value.map(navItem => {
                    return (
                      <Link
                        to={
                          navItem.elements.external_link.value === ""
                            ? `/${navItem.elements.page.value[0].elements.slug.value}`
                            : `${navItem.elements.external_link.value}`
                        }
                        className={styles.portalLink}
                      >
                        {navItem.elements.link_text.value}
                      </Link>
                    )
                  })}
              </div>
            )}

            <a href={`tel: ${phone}`} className={styles.phoneLink}>
              <FaPhoneAlt />
              {phone}
            </a>
            {size < 1024 && (
              <Link to="/contact-us" className={styles.contactButton}>
                Contact Us <BsEnvelope />
              </Link>
            )}
          </div>
        </div>
      </section>
    )
  }
}

SecondaryHeader.defaultProps = {
  phone: "(888) 888-8888",
}

export default SecondaryHeader
