import React from "react"
import { GoAlert } from "react-icons/go"
import styles from "./alert.module.scss"
const AlertBanner = ({ bannerContent, showAlert }) => {
  const { alert_text, icon, link } = bannerContent.value[0].elements
  if (showAlert !== "yes") return null
  const getIcon = () => {
    switch (icon.value[0].codename) {
      case "alert":
        return <GoAlert />
      default:
        break
    }
  }
  return (
    <div className={styles.emergencyTicker}>
      <div className={styles.marquee}>
        {getIcon()}
        <a href={link.value}>{alert_text.value}</a>
      </div>
    </div>
  )
}

export default AlertBanner
